import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebaseUiConfig from 'config/firebaseui';

export default class Login extends React.Component {

  render() {
    const uiConfig = Object.assign({
      callbacks: {
        signInSuccessWithAuthResult: () => false
      }
    }, firebaseUiConfig);

    return (
      <Container>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()} />

        <p className="text-center">
          <br/>
          Don{"'"}t have an account? <Link to="/register">Register as a volunteer</Link>.
        </p>
      </Container>
    );
  }

}
