import React, { Component } from 'react';
import PropType from 'prop-types';
import './SchedulePage.css';
import moment from 'moment';
import BackendAPI from '../../services/BackendAPI';
import { changeColor } from '../../utils/changeColor';
import { Button } from 'reactstrap';

export default class EventSlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      volunteer: {},
      checkedIn: false,
      checkedOut: false,
    };
  }

  componentDidMount() {
    BackendAPI.getEvent(this.props.eventId).then(response => {
      if (response.volunteers.length > 0) {
        const volunteer = response.volunteers.find(
          v => v.id === this.props.user.id
        );
        const { checkedIn, checkedOut } = volunteer;
        this.setState({ event: response, volunteer, checkedIn, checkedOut });
      }
    });
  }

  handleCheckIn = () => {
    BackendAPI.checkInUserToEvent(this.props.eventId, this.props.user.uid)
      .then(response => this.setState({ checkedIn: true }))
      .catch(e => console.log(e));
  };

  handleCheckOut = () => {
    BackendAPI.checkOutUserFromEvent(this.props.eventId, this.props.user.uid)
      .then(response => this.setState({ checkedOut: true }))
      .catch(e => console.log(e));
  };

  displayCheckInCheckout = () => {
    const { volunteer, checkedIn, checkedOut } = this.state;
    if (!volunteer) {
      return;
    }

    if (checkedIn === false) {
      return (
        <Button color="success" onClick={this.handleCheckIn}>
          check-in
        </Button>
      );
    } else if (checkedIn === true && checkedOut === false) {
      return (
        <Button color="danger" onClick={this.handleCheckOut}>
          check-out
        </Button>
      );
    } else {
      return;
    }
  };

  render() {
    const today = Date.now();
    const eventDate = this.props.event.event_start;
    const convertTime = moment(eventDate, 'YYYY-MM-DD HH:mm:ss').unix();
    const bgColor =
      today > convertTime
        ? changeColor(this.props.event['event_subtype'])
        : this.changeColor('Out of Date');
    const time = eventDate.split(' ')[1];
    const displayTime = time.substring(0, time.length - 3);
    const textColor = 'black';
    return (
      <div className="EventSlotDiv">
        <div className="EventStartTimeDiv">{displayTime}</div>
        <div
          className="EventNameDiv"
          style={{ backgroundColor: bgColor, color: textColor }}
          onClick={() => this.props.handleAdminClick(this.props.eventId)}
        >
          {this.props.event.name}
        </div>
        {this.displayCheckInCheckout()}
      </div>
    );
  }
}

EventSlot.propTypes = {
  event: PropType.object,
  eventId: PropType.string,
  user: PropType.object,
  handleAdminClick: PropType.func,
};
