import React from 'react';
import {
  Alert,
  FormGroup,
  Input,
  Container,
  Row,
  Col
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import PhoneNumber from 'awesome-phonenumber';
import EmailValidator from 'email-validator';
import Button from 'components/CustomButton/CustomButton';
import BackendAPI from 'services/BackendAPI';

const getInputValues = () => {
  const firstName = document.getElementById('firstName').value;
  const lastName = document.getElementById('lastName').value;
  const phoneNumber = document.getElementById('phoneNumber').value;
  const email = document.getElementById('email').value;
  const password = document.getElementById('password').value;
  const confirmPassword = document.getElementById('confirmPassword').value;

  return { firstName, lastName, phoneNumber, email, password, confirmPassword };
};

export default class Login extends React.Component {

  state = {
    isFirstNameDirty: false,
    isFirstNameValid: false,
    isLastNameDirty: false,
    isLastNameValid: false,
    isPhoneNumberDirty: false,
    isPhoneNumberValid: false,
    isEmailDirty: false,
    isEmailValid: false,
    isPasswordDirty: false,
    isPasswordValid: false,
    isConfirmPasswordDirty: false,
    isConfirmPasswordValid: false,
    isFormValid: false,
    isLoading: false,
    isRegistrationComplete: false,
    alert: null
  };

  handleRegister = () => {
    if (!this.state.isFormValid) { return; }
    this.setState({ isLoading: true });

    const values = getInputValues();
    values.phoneNumber = new PhoneNumber(values.phoneNumber, 'CA').getNumber();
    delete values.confirmPassword;

    BackendAPI.createUser(values)
      .then(() => this.setState({ isRegistrationComplete: true }))
      .catch(e => {
        /* eslint-disable-next-line */
        console.error(e);

        let alert = e.message;

        if (e.errors && e.errors.length > 0) {
          alert = e.errors[0].msg;
        }

        this.setState({
          alert,
          isLoading: false
        });
      });
  };

  handleInputChange = (inputId) => {
    const values = getInputValues();
    const dirtyInputState = `is${inputId.charAt(0).toUpperCase() + inputId.slice(1)}Dirty`;
    const stateChange = { [dirtyInputState]: true };

    stateChange.isFirstNameValid = values.firstName.length > 0;
    stateChange.isLastNameValid = values.lastName.length > 1;
    stateChange.isPhoneNumberValid = new PhoneNumber(values.phoneNumber, 'CA').isValid();
    stateChange.isEmailValid = EmailValidator.validate(values.email);
    stateChange.isPasswordValid = values.password.length > 6;
    stateChange.isConfirmPasswordValid = values.password === values.confirmPassword;

    stateChange.isFormValid = stateChange.isFirstNameValid &&
      stateChange.isLastNameValid &&
      stateChange.isPhoneNumberValid &&
      stateChange.isEmailValid &&
      stateChange.isPasswordValid &&
      stateChange.isConfirmPasswordValid;

    this.setState(stateChange);
  };

  dismissAlert = () => this.setState({ alert: null });

  render() {
    if (this.state.isRegistrationComplete) {
      return <Redirect to="/" />;
    }

    if (this.state.isLoading) {
      return (
        <Container className="text-center">
          <h1><i className="fas fa-spinner fa-spin" /></h1>
        </Container>
      );
    }

    return (
      <Container>
        <h2>Volunteer Registration</h2>
        <p>Already have an account? <Link to="/">Login here</Link>.</p>

        <Alert color="danger" isOpen={!!this.state.alert} toggle={this.dismissAlert}>
          {this.state.alert}
        </Alert>

        <form>
          <Row>
            <Col xs="12" md="6">
              <FormGroup>
                <Input
                  invalid={this.state.isFirstNameDirty && !this.state.isFirstNameValid}
                  onChange={() => this.handleInputChange('firstName')}
                  type="text" id="firstName" placeholder="First Name" />
              </FormGroup>
            </Col>

            <Col xs="12" md="6">
              <FormGroup>
                <Input
                  invalid={this.state.isLastNameDirty && !this.state.isLastNameValid}
                  onChange={() => this.handleInputChange('lastName')}
                  type="text" id="lastName" placeholder="Last Name" />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <FormGroup>
                <Input
                  invalid={this.state.isPhoneNumberDirty && !this.state.isPhoneNumberValid}
                  onChange={() => this.handleInputChange('phoneNumber')}
                  type="text" id="phoneNumber" placeholder="Phone Number" />
              </FormGroup>
            </Col>

            <Col xs="12" md="6">
              <FormGroup>
                <Input
                  invalid={this.state.isEmailDirty && !this.state.isEmailValid}
                  onChange={() => this.handleInputChange('email')}
                  type="email" id="email" placeholder="Email Address" />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <FormGroup>
                <Input
                  invalid={this.state.isPasswordDirty && !this.state.isPasswordValid}
                  onChange={() => this.handleInputChange('password')}
                  type="password" id="password" placeholder="Password" />
              </FormGroup>
            </Col>

            <Col xs="12" md="6">
              <FormGroup>
                <Input
                  invalid={this.state.isConfirmPasswordDirty && !this.state.isConfirmPasswordValid}
                  onChange={() => this.handleInputChange('confirmPassword')}
                  type="password" id="confirmPassword" placeholder="Confirm Password" />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="text-right">
              <Button color="default" className={this.state.isFormValid ? '' : 'disabled'} onClick={this.handleRegister}>
                Register
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    );
  }
}
