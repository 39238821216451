import React, { useState } from 'react';
import PropType from 'prop-types';

import { Button } from 'reactstrap';

export const SelectDropDownVolunteer = ({ dataList, handleSubmit, buttonText }) => {
  const [value, setValue] = useState('')

  const renderList = () => dataList.map(({ _id, displayName, uid }) => (
    <option key={_id} value={uid}>
      {displayName || uid}
    </option>
  ));

  const handleChangeVolunteer = e => setValue(e.target.value);


  return (
    <div>
      {dataList.length > 0 ? (
        <select onChange={handleChangeVolunteer} value={value}>
          <option>Please Select Volunteer</option>
          {renderList()}
        </select>
      ) : (
          ''
        )}
      <Button onClick={() => handleSubmit(value)}>
        {buttonText}
      </Button>
    </div>
  );
}

SelectDropDownVolunteer.propTypes = {
  dataList: PropType.arrayOf(Object),
  handleChange: PropType.func,
  handleSubmit: PropType.func,
  buttonText: PropType.string,
};

export default SelectDropDownVolunteer;