export const changeColor = (category) => {
  switch (category) {
    case 'Blockchain':
      return '#FF4103'
    case 'Business':
      return '#E1727C'
    case 'Cannabis':
      return '#FF72D7'
    case 'Community':
      return '#FCD7FF'
    case 'Edtech':
      return '#D7BEF3'
    case 'Finance & Investment':
      return '#C5C8FF'
    case 'Food & Beverage':
      return '#5B7AFF'
    case 'Health and Wellness':
      return '#1DB8FF'
    case 'HR & Culture':
      return '#26DFFF'
    case 'Legal':
      return '#ABE5FF'
    case 'Marketing':
      return '#B3FFF1'
    case 'Media & PR':
      return '#3FFFD5'
    case 'Network & Relationship Building':
      return '#35DF9D'
    case 'Recruitment':
      return '#72FD1A'
    case 'Robotics & AI':
      return '#CFFDAE'
    case 'Security & Cybersecurity':
      return '#F9FFC8'
    case 'Technology':
      return '#FCFF41';
    case 'UX/UI':
      return '#F3AD1A'
    case 'Out of Date':
      return 'grey'
    default:
      break;
  }
}