import React from 'react';
import ReactLoading from 'react-loading';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Table, Row, Col } from 'reactstrap';
import Button from 'components/CustomButton/CustomButton'
import BannerCreator from '../components/BannerCreator';
import BackendAPI from 'services/BackendAPI';

export default class Banners extends React.Component{

    constructor() {
        super();
        this.state = {
            viewForm: false,
            banners: null
        };
        this.openForm = this.openForm.bind(this);
        this.closeForm = this.closeForm.bind(this);
      }

    componentDidMount() {
      this.fetchBanners();
    }

    fetchBanners = () => {
      BackendAPI.getAllBanners()
      .then((banners) => {
          this.setState({ banners });
      })
      .catch(err => console.error(err));
    }

    resetState = () => {
      this.setState({
        viewForm: false,
        banners: null
    });
    }

    openForm = () => {
        this.setState({
            viewForm: true
        });
    }

    closeForm = () => {
      this.resetState();
      this.fetchBanners();
    }

    renderBannerRow = (banner, key) => (
        <tr key={key}>
        <td>
          <Link to={`/banner/${banner._id.toString()}`}>
          <strong>{ banner.name }</strong>
          </Link> 
          
        </td>
        <td>{ banner.state }</td>
        <td>{ banner.curEventId === null ? 'None' : banner.curEventId }</td>
        <td>{ banner.curVolunteerId === null ? 'None' : banner.curVolunteerId }</td>
      </tr>
    );

    renderBannerCreator = () => (
        <BannerCreator action={this.closeForm}></BannerCreator>
    );

    renderBannersList = () => (
      <div>
        <Table responsive>
          <thead className='text-primary'>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Event</th>
              <th>Volunteer</th>
              <th>Alert</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.banners.map(
                (banner, key) => this.renderBannerRow(banner, key)
              )
            }
          </tbody>
        </Table>
      </div>
    );

    renderBanners = () => {
      let table = (this.state.banners) ? this.renderBannersList() : this.renderLoading()
      return(
        <Col md={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col xs='6'>
                  <CardTitle tag='h4'>Banner Inventory</CardTitle>
                </Col>
                <Col xs='6'>
                  <div className='text-right'>
                    <Button
                      onClick={this.openForm}
                      leftLabel='fas fa-plus-circle'
                      color='success'>
                      Create Banner
                            </Button>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {table}
            </CardBody>
          </Card>
        </Col>
      );
    }

    renderLoading = () => (
        <div>
          <ReactLoading
            className="margin-zero-auto"
            type="bubbles" color="#222222" />
        </div>
      );
    
    render(){    
            return(
                <div className='content'>{
                this.state.viewForm ? this.renderBannerCreator() : this.renderBanners()
                }
              </div>
            );
    }
}
