import firebase from 'firebase';
import uuidv4 from 'uuid/v4';

export default class ImageHandler {

    /*Verifies the file is an image by checking file header then resolves with the validated file or an error */
    static async verifyFile(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onloadend = function (event) {
                const arr = new Uint8Array(event.target.result).subarray(0, 4);
                let header = '';
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }
                const result = {}
                switch (header) {
                    case '89504e47': //type = 'image/png'
                        result.file = file;
                        result.extenstion = '.png';
                        break;
                    case 'ffd8ffe0': //type = 'image/jpeg';
                    case 'ffd8ffe1':
                    case 'ffd8ffe2':
                    case 'ffd8ffe3':
                    case 'ffd8ffe8':
                        result.file = file;
                        result.extension = '.jpg';
                        break;
                    default:
                        //else
                        return reject('Uploaded File type is not supported');
                }
                resolve(result);
            };
            fileReader.readAsArrayBuffer(file);
        });
    }

    /*Uploads Image to Firebase and resolves with the image URL returned by firebase */
    static async uploadImage({ file, extension }) {
        const storageRef = firebase.storage().ref(`bannerImages/${uuidv4()}${extension}`) //Create Storage Ref

        return new Promise((resolve, reject) => {
            let task = storageRef.put(file); //Initiate Upload file task

            const uploadErr = (err) => reject(`Failed to upload to Firebase: ${err.code}`);
            const uploadComplete = () => {
                // Upload completed successfully, now we can get the download URL
                task.snapshot.ref.getDownloadURL().then(downloadURL => {
                    //resolve with the image URL
                    resolve(downloadURL.toString());
                });
            }
            task.on('state_changed', () => { }, uploadErr, uploadComplete);
        });
    }

}
