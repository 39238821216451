import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';

import vswLogo from 'assets/img/vsw-logo.svg';

class Sidebar extends React.Component {

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  render() {
    return (
      <div className="sidebar" data-color="black" data-active-color="info">
        <div className="logo">
          <Link to="/" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={vswLogo} alt="Vancouver Startup Week" />
            </div>
          </Link>

          <Link to="/" className="simple-text logo-normal">VSW HQ</Link>
        </div>
        { /* eslint-disable-next-line */ }
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? ' active-pro' : '')
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }

  static propTypes = {
    location: PropTypes.object,
    routes: PropTypes.array
  };

}

export default Sidebar;
