import React from 'react';
import { Container, Row } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';

class Footer extends React.Component {

  render() {
    return (
      <footer
        className={'footer' + (this.props.default ? ' footer-default' : '')}
      >
        <Container fluid={!!this.props.fluid}>
          <Row>
            <div className='credits ml-auto'>
              <div className='copyright'>
                Made with <i className='fa fa-heart heart'></i> by <strong>VSW Tech</strong>
              </div>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }

  static propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool
  };

}

export default Footer;
