import Volunteers from 'views/Volunteers';
import Banners from 'views/Banners';
import BannerProfile from 'views/BannerProfile';
import Account from 'views/Account';
import Schedule from 'views/SchedulePage';

const dashRoutes = [
  {
    path: '/schedule',
    name: 'Schedule',
    icon: 'fas fa-calendar-alt',
    component: Schedule
  },
  {
    path: '/volunteers',
    name: 'Volunteers',
    icon: 'fas fa-users',
    component: Volunteers,
    authorizedIf: 'ADMIN'
  },
  {
    path: '/banners',
    name: 'Banners',
    icon: 'fas fa-flag',
    component: Banners,
    authorizedIf: 'ADMIN'
  },
  {
    path: '/banner/:id',
    name: 'Banner Profile',
    icon: 'fas fa-flag',
    component: BannerProfile,
    authorizedIf: 'ADMIN',
    hideFromSideBar: true
  },
  {
    path: '/account',
    name: 'Account',
    icon: 'fas fa-user-circle',
    component: Account
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/schedule',
    name: 'Schedule'
  }
];

export default dashRoutes;
