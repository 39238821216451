import React from 'react';
import ReactLoading from 'react-loading';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import Button from 'components/CustomButton/CustomButton.jsx';
import BackendAPI from 'services/BackendAPI';
import ImageHandler from 'services/ImageHandler';

class BannerCreator extends React.Component {
  constructor() {
    super();
    this.state = {
      bannerName: '',
      bannerType: '',
      description: '',
      bannerImage: null,
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);
    this.createBanner = this.createBanner.bind(this);
  }

  /* Event handler for changes in Form inputs */
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'file' ? target.files[0] : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  /* resets the form fields to empty */
  resetState() {
    this.setState({
      bannerName: '',
      bannerType: '',
      description: '',
      bannerImage: null
    });
  }

  /* Uses BackEndAPI to send a POST request to create a banner resolves with success or a fail */
  async createBanner(bannerName, imageUrl, bannerType, bannerDescription) {
    //create JSON object to use as body in request
    let collection = {};
    collection.name = bannerName;
    collection.imageUrl = imageUrl;
    collection.type = bannerType;
    if (bannerDescription !== '') {
      collection.description = bannerDescription;
    }

    //use BackendAPI to create a banner
    return BackendAPI.createBanner(collection);
  }

  /*Sequentially verifies file, uploads it, then creates a banner using the returned url and form data */
  handleSubmit(event) {
    const uploadedFile = this.state.bannerImage;
    const bannerName = this.state.bannerName;
    const bannerType = this.state.bannerType;
    const bannerDescription = this.state.description;
    event.preventDefault();

    this.setState({ isLoading: true });
    ImageHandler.verifyFile(uploadedFile)
      .then(validImage => ImageHandler.uploadImage(validImage))
      .then(imageUrl =>
        this.createBanner(bannerName, imageUrl, bannerType, bannerDescription)
      )
      .then(() => {
        this.setState({ isLoading: false });
        let options = {
          place: 'tc',
          message: <b>Successfully created Banner</b>,
          type: 'success',
          icon: 'fas fa-check',
          autoDismiss: 3,
          closeButton: false
        };
        this.refs.notificationAlert.notificationAlert(options);
      })
      .catch(error => {
        let options = {
          place: 'tc',
          message: error,
          type: 'danger',
          icon: 'fas fa-exclamation',
          autoDismiss: 3,
          closeButton: false
        };
        this.refs.notificationAlert.notificationAlert(options);
      });

    //reset form fields
    this.resetState();
  }

  render() {
    const { bannerName, bannerType, bannerImage } = this.state;
    const isEnabled =
      bannerName.length > 0 && bannerType.length > 0 && bannerImage !== null;

    if (this.state.isLoading) {
      return (
        <div className='content'>
          <NotificationAlert ref='notificationAlert' />
          <Col md='6'>
          <div className='text-left'>
                <Button
                    leftLabel='fas fa-chevron-left'
                    className='btn-round'
                    color='primary'
                    disabled>
                  Go Back
                </Button>
              </div>
            <Card className='card-user'>
              <CardHeader>
                <CardTitle>Banner Information</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactLoading
                  className='margin-zero-auto'
                  type='spinningBubbles'
                  color='grey'
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      );
    }

    return (
      <div className='content'>
        <NotificationAlert ref='notificationAlert' />
        <Col md='6'>
        <div className='text-left'>
                <Button
                    onClick={() => this.props.action()}
                    leftLabel='fas fa-chevron-left'
                    className='btn-round'
                    color='primary'>
                  Go Back
                </Button>
              </div>
          <Card className='card-user'>
            <CardHeader>
              <CardTitle>Banner Information</CardTitle>
            </CardHeader>
            <CardBody>
              <form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <label>Banner Name</label>
                  <Input
                    type='text'
                    name='bannerName'
                    value={this.state.bannerName}
                    placeholder='Banner Name'
                    onChange={this.handleChange}
                  />
                </FormGroup>

                <label>Banner Type</label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type='radio'
                      name='bannerType'
                      value='vsw'
                      checked={this.state.bannerType === 'vsw'}
                      onChange={this.handleChange}
                    />
                    <font color='#66615b'>VSW</font>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type='radio'
                      name='bannerType'
                      value='sponsor'
                      checked={this.state.bannerType === 'sponsor'}
                      onChange={this.handleChange}
                    />
                    <font color='#66615b'>Sponsor</font>
                  </Label>
                </FormGroup>

                <label>Banner Image</label>

                <div style={{ marginBottom: 20 + 'px' }}>
                  <Input
                    type='file'
                    name='bannerImage'
                    onChange={this.handleChange}
                    accept='image/*'
                  />
                </div>

                <FormGroup>
                  <label>Description (optional)</label>
                  <Input
                    type='textarea'
                    name='description'
                    value={this.state.description}
                    placeholder='Add a Short Description'
                    onChange={this.handleChange}
                  />
                </FormGroup>

                <Row>
                  <div className='update ml-auto mr-auto'>
                    <Button
                      type='submit'
                      color='success'
                      round
                      disabled={!isEnabled}
                    >
                      Create Banner
                    </Button>
                  </div>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default BannerCreator;
