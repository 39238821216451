import React, { Component } from 'react';
import ImageUploader from 'react-images-upload';
import { Card, CardHeader, CardBody } from 'reactstrap';
import './UserProfile.css';
export default class ProfilePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: []
    };
  }

  onDrop = (pictureFiles, picDataUrls) => {
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles)
    });
  };

  render() {
    return (
      <Card>
        <CardHeader className="Title">Drop your Image here</CardHeader>
        <CardBody>
          <ImageUploader
            withIcon={true}
            buttonText="Choose Image"
            onChange={this.onDrop}
            imageExtension={['.jpg', 'jpeg']}
            withPreview={true}
            maxFileSize={5242880}
            label="Max file size: 5mb, accepted: jpg"
          />
        </CardBody>
      </Card>
    );
  }
}
