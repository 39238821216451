import React from 'react';
import firebase from 'firebase';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Button from 'components/CustomButton/CustomButton'

const renderUserDetail = (name, value) => (
  <Card style={{width: '100%'}}>
    <CardBody>
      <strong>{name}</strong>
      <p>{value}</p>
    </CardBody>
  </Card>
);

export default class Account extends React.Component {

  render() {
    const user = firebase.auth().currentUser.toJSON();

    return (
      <div className="content">
        <h2>{user.displayName}</h2>

        <Row>
          <Col xs="12" md="3">
            {renderUserDetail('User ID', user.uid)}
          </Col>

          <Col xs="12" md="3">
            {renderUserDetail('Email', user.email)}
          </Col>

          <Col xs="12" md="3">
            {renderUserDetail('Password', '*********')}
          </Col>

          <Col xs="12" md="3">
            {renderUserDetail('Phone Number', user.phoneNumber)}
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="12">
            <div className="text-right">
              <Button
                onClick={() => firebase.auth().signOut()}
                leftLabel="fas fa-sign-out-alt"
                className="bg-danger">
                Logout
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

}
