import React, { Component } from 'react';
import PropType from 'prop-types';
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  Button,
} from 'reactstrap';
import BackendAPI from '../../../services/BackendAPI';
import SelectDropdownVolunteer from './SelectDropDown';
import SelectDropdownBanner from './SelectDropDownBanner';
//import _ from 'lodash';
export default class EventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: null,
      schedId: '',
      banners: [],
      volunteers: [],
      error: false,
      message: '',
      isLoading: true,
      selectedVolunteer: '',
      dataListVolunteer: [],
      selectedBanner: '',
      dataListBanner: [],
    };
  }

  loadEvent = () => {
    BackendAPI.getEvent(this.props.eventId)
      .then(response => {
        console.log(response)
        this.setState({
          eventData: response.meta,
          banners: [...response.banners],
          volunteers: [...response.volunteers],
          schedId: response.schedId,
          isLoading: false,
        });
      })
      .catch(e => this.setState({ error: true, message: e }));
    BackendAPI.getAllUsers()
      .then(response => {
        const filteredList = response.filter((v) => v.role === 'DEFAULT')
        this.setState({ dataListVolunteer: filteredList })
      })
      .catch(e => console.log(e));

    BackendAPI.getAllBanners()
      .then(response => {
        // if we want to filter out assigned and existing banner that have events
        // const filteredList = [];
        // response.forEach(banner => {
        //   if (!_.includes(this.state.banners, banner._id) && banner.events.length === 0) filteredList.push(banner)
        // });
        this.setState({ dataListBanner: response })
      })
      .catch(e => console.log(e));
  }

  componentDidMount() {
    this.loadEvent()
  }

  handleSubmitVolunteer = (value) => {
    BackendAPI.assignUserToEvent(
      this.props.eventId,
      value
    )
      .then(response => this.loadEvent())
      .catch(e => console.log(e));
  };

  handleSubmitBanner = (value) => {
    BackendAPI.assignBannerToEvent(
      this.props.eventId,
      value
    )
      .then(response => this.loadEvent())
      .catch(e => console.log(e));
  };

  volunteerDisplayName = id => {
    const result = this.state.dataListVolunteer.find(v => v._id === id);
    return result.displayName;
  };

  displayBannerName = bannerId => {
    const result = this.state.dataListBanner.find(b => b._id === bannerId);
    return result.name
  }

  handleRemoveUser = id => {
    BackendAPI.removeUserFromEvent(this.props.eventId, id)
      .then(response => this.loadEvent())
      .catch(e => console.log(e));
  };

  handleRemoveBanner = id => {
    BackendAPI.removeBannerFromEvent(this.props.eventId, id)
      .then(response => this.loadEvent())
      .catch(e => console.log(e));
  };

  renderBody = () => {
    const { banners, volunteers, eventData } = this.state;
    const eventStart = eventData.event_start.split(' ')[1];
    const eventEnd = eventData.event_end.split(' ')[1];
    const startTime = eventStart.substring(0, eventStart.length - 3);
    const endTime = eventEnd.substring(0, eventEnd.length - 3);
    return (
      <CardBody>
        <CardTitle>{eventData.name}</CardTitle>
        <CardSubtitle>
          Event Time: {startTime} - {endTime}
        </CardSubtitle>
        <CardText>Venue: {eventData.venue}</CardText>
        <h4>Volunteers</h4>
        {volunteers.length > 0
          ? volunteers.map(volunteer => (
            <>
              <li key={volunteer._id}>
                {this.volunteerDisplayName(volunteer.volunteerId)}
              </li>{' '}
              <Button key={volunteer.volunteerId}
                onClick={() => this.handleRemoveUser(volunteer.volunteerId)}
              >
                Remove
                </Button>
            </>
          ))
          : 'No Volunteers assigned to this event yet'}
        <SelectDropdownVolunteer
          buttonText={'Add Volunteer'}
          dataList={this.state.dataListVolunteer}
          handleSubmit={this.handleSubmitVolunteer}
        />
        <h4>Banners</h4>
        {banners.length > 0
          ? banners.map(banner => (
            <>
              <li key={banner}>{this.displayBannerName(banner)}</li>{' '}
              <Button onClick={() => this.handleRemoveBanner(banner)}>
                Remove
                </Button>
            </>
          ))
          : 'No Banner Asssigned to this event'}
        <SelectDropdownBanner
          buttonText={'Add Banner'}
          dataList={this.state.dataListBanner}
          handleSubmit={this.handleSubmitBanner}
        />
      </CardBody>
    );
  };

  render() {
    const isLoading = this.state.isLoading;
    return (
      <Col>
        <Card>{isLoading ? 'loading' : this.renderBody()}</Card>
      </Col>
    );
  }
}

EventDetail.propTypes = {
  eventId: PropType.string,
};
