import React, { useState } from 'react';
import PropType from 'prop-types';

import { Button } from 'reactstrap';

const SelectDropDownBanner = ({ dataList, handleChange, handleSubmit, buttonText }) => {
  const [value, setValue] = useState('')

  const renderList = () =>
    dataList.map(({ _id, name }) => (
      <option key={_id} value={_id}>
        {name}
      </option>
    ));

  const handleChangeBanner = (e) => setValue(e.target.value)
  return (
    <div>
      {dataList.length > 0 ? (
        <select onChange={handleChangeBanner} value={value}>
          <option> Please Select Banner </option>
          {renderList()}
        </select>
      ) : (
          ''
        )}
      <Button onClick={() => handleSubmit(value)}>
        {buttonText}
      </Button>
    </div>
  );
}

SelectDropDownBanner.propTypes = {
  dataList: PropType.arrayOf(Object),
  handleChange: PropType.func,
  handleSubmit: PropType.func,
  buttonText: PropType.string,
};

export default SelectDropDownBanner;