import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import { createBrowserHistory } from 'history';

import App from './App';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/main.scss';
import 'assets/scss/BannerProfile.scss';

const hist = createBrowserHistory();

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET
});

ReactDOM.render(<App hist={hist} />, document.getElementById('root'));
