import React from 'react';
import ReactLoading from 'react-loading';
import { Row, Card, Col, Container, Table } from 'reactstrap';
import firebase from 'firebase';
import Button from 'components/CustomButton/CustomButton';
import UserProflieModal from './UserProfile/UserProflieModal';
import BackendAPI from 'services/BackendAPI';

export default class Volunteers extends React.Component {

  state = {
    volunteers: null,
    isActionsDisabled: false
  };

  componentDidMount() {
    BackendAPI.getAllUsers()
      .then((volunteers) => this.setState({ volunteers }))
      .catch(err => console.error(err));
  }

  toggleUser = user => {
    this.setState({ isActionsDisabled: true });

    BackendAPI.toggleAdmin(user.uid)
      .then(updatedUser => {
        const { volunteers } = this.state;
        const i = volunteers.findIndex(volunteer => volunteer.uid === updatedUser.uid);

        volunteers[i] = updatedUser;

        this.setState({
          volunteers,
          isActionsDisabled: false
        });
      })
      .catch(err => {
        this.setState({ isActionsDisabled: false });
        console.error(err);
      })
  };

  renderVolunteerRow = (user, i, isDisabled = false) => (
    <tr key={i}>
      <td><strong><UserProflieModal user={user} buttonLabel={user.displayName} /></strong></td>
      <td>{user.email}</td>
      <td>{user.role === 'ADMIN' ? 'Core Team' : 'General Volunteer'}</td>
      <td>
        <Button className={`${isDisabled ? 'disabled' : ''} bg-danger`} onClick={() => this.toggleUser(user)}>
          {user.role === 'ADMIN' ? 'Make General Volunteer' : 'Add To Core Team'}
        </Button>
      </td>
    </tr>
  );

  renderVolunteers = () => (
    <Container fluid={true}>
      <Row>
        <Col xs="12" md="12">
          <Card>
            <Table responsive>
              <thead className="text-primary">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {
                  this.state.volunteers.map(
                    (user, i) => this.renderVolunteerRow(user, i, firebase.auth().currentUser.email === user.email || this.state.isActionsDisabled)
                  )
                }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  renderLoading = () => (
    <div>
      <ReactLoading
        className="margin-zero-auto"
        type="bubbles" color="#222222" />
    </div>
  );

  render() {
    return (
      <div className="content">
        {
          this.state.volunteers ?
            this.renderVolunteers() :
            this.renderLoading()
        }
      </div>
    );
  }

}
