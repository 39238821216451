import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import PropType from "prop-types";
import "./UserProfile.css";

//TODO: Currently dont have backend route for this
export const MessageBoard = props => {
  return (
    <Card>
      <CardHeader className="Title">Messages</CardHeader>
      <CardBody>{props.messages}</CardBody>
    </Card>
  );
};

MessageBoard.propTypes = {
  messages: PropType.string
};
