import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import FullScreenLoader from 'components/FullScreenLoader';
import dashboardRoutes from 'routes/dashboard';
import BackendAPI from 'services/BackendAPI';

const routesFor = ({ role }) => dashboardRoutes
  .filter(({ authorizedIf }) => !authorizedIf || role === authorizedIf);

class Dashboard extends React.Component {

  state = {
    currentUser: null
  };

  componentDidMount() {
    BackendAPI.getUser()
      .then(currentUser => this.setState({ currentUser }))
      .catch(e => console.error(e));
  }

  render() {
    if (!this.state.currentUser) {
      return <FullScreenLoader/>;
    }

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routesFor(this.state.currentUser).filter(({ hideFromSideBar }) => !hideFromSideBar)}
        />
        { /* eslint-disable-next-line */ }
        <div className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Switch>
            {
              routesFor(this.state.currentUser)
                .map((prop, key) => {
                  if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                  }

                  return <Route path={prop.path} component={prop.component} key={key} />;
                })
            }
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }

}

export default Dashboard;
