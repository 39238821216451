import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import BackendAPI from '../../services/BackendAPI';
import PropTypes from 'prop-types';
import HistoryTable from './HistoryTable';
import EventStatus from './EventStatus';
import EventTeam from './EventTeam';
import { MessageBoard } from './MessageBoard';
import ProfilePhoto from './ProfilePhoto';
import './UserProfile.css';
import { BasicInfoTable } from './BasicInfoTable';

export default class UserProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nestedModal: false,
      closeAll: false,
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      phoneNumber: '',
      messages: ''
    };
  }
  componentDidMount() {
    //
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  toggleNested = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleUpdate = e => {
    e.preventDefault();
    BackendAPI.updateUser(this.props.user.uid, {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address
    });
    this.setState({ nestedModal: false });
  };

  deleteProfile = () => {
    BackendAPI.deleteUser(this.props.user.id);
    this.setState({ modal: false });
  };

  renderForm = (firstName, lastName, user) => (
    <Form onSubmit={this.handleUpdate}>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              defaultValue={firstName}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="Last Name">Last Name</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              defaultValue={lastName}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="emailAddress">Email</Label>
        <Input
          type="email"
          name="email"
          id="emailAddress"
          placeholder="xxx@email.com"
          defaultValue={this.props.user.email}
          onChange={this.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="phoneNumber">Phone Number</Label>
        <Input
          type="text"
          name="phoneNumber"
          id="phoneNumber"
          placeholder="Phone number"
          defaultValue={this.props.user.phoneNumber}
          onChange={this.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="Address">Address</Label>
        <Input
          type="text"
          name="address"
          id="Address"
          placeholder="Apartment, studio, or floor"
          defaultValue={this.props.user.address}
          onChange={this.handleChange}
        />
      </FormGroup>
      <Button color="secondary" type="submit">
        Update Info
      </Button>
    </Form>
  );

  render() {
    const [firstName, lastName] = this.props.user.displayName.split(' ');
    const messages =
      this.state.messages.length === 0
        ? 'Currently No Messages'
        : this.state.messages;
    return (
      <div>
        <a color="danger" onClick={this.toggle}>
          {this.props.buttonLabel}
        </a>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size="xl"
        >
          <ModalHeader toggle={this.toggle}>
            {this.props.buttonLabel}
          </ModalHeader>
          <ModalBody className="ModalBody">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Card>
                        <CardHeader className="Title">Basic Info</CardHeader>
                        <CardBody>
                          <BasicInfoTable user={this.props.user} firstName={firstName} lastName={lastName} />
                        </CardBody>
                        <Col className="BasicInfoButton">
                          <Button color="warning" onClick={this.toggleNested}>
                            Edit Info
                          </Button>
                          <Button color="danger" onClick={this.deleteProfile}>
                            Delete Profile
                          </Button>
                          <Modal
                            isOpen={this.state.nestedModal}
                            toggle={this.toggleNested}
                            onClosed={
                              this.state.closeAll ? this.toggle : undefined
                            }
                          >
                            <ModalHeader>Edit</ModalHeader>
                            <ModalBody>
                              {this.renderForm(
                                firstName,
                                lastName,
                                this.props.user
                              )}
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="secondary"
                                onClick={this.toggleNested}
                              >
                                Close
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <ProfilePhoto />
                </Col>
                <Col>
                  <MessageBoard messages={messages} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EventStatus user={this.props.user} />
                </Col>
                <Col xs={4}>
                  <EventTeam user={this.props.user} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <HistoryTable userEvents={this.props.user.event} />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

UserProfileModal.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  user: PropTypes.object
};
