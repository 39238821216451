import React, { Component } from 'react';
import BackendAPI from 'services/BackendAPI';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import DayDisplay from './DayDisplay';
import EventDetail from './EventDetail/index';
import { groupBy } from 'utils/groupBy';
import moment from 'moment';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      currentUser: null,
      adminWindow: false,
      displayEventId: null,
    };
  }

  componentWillMount() {
    BackendAPI.getUser()
      .then(currentUser =>
        this.setState({ events: currentUser.events, currentUser })
      )
      .catch(e => console.error(e));
  }

  handleAdminClick = eventId => {
    if (this.state.currentUser.role === 'ADMIN') {
      this.setState({
        adminWindow: !this.state.adminWindow,
        displayEventId: eventId,
      });
    }
  };

  //TODO: Grey out Past Event
  renderDayDisplay = () => {
    if (this.state.events.length !== 0) {
      //TODO: Sort by time
      const byDate = groupBy(this.state.events, 'meta', 'event_start');
      const dates = Object.keys(byDate);
      const filterByDate = date => {
        return this.state.events.filter(event => {
          const eventStart = event.meta.event_start.split(' ')[0];
          const formatDate = date.split(' ')[0];
          if (
            moment().format(eventStart, 'MM-DD-YY') ===
            moment().format(formatDate, 'MM-DD-YY')
          ) {
            return true;
          } else {
            return false;
          }
        });
      };
      return dates.map((date, i) => (
        <DayDisplay
          key={i}
          date={date}
          events={filterByDate(date)}
          user={this.state.currentUser}
          handleAdminClick={this.handleAdminClick}
        />
      ));
    } else {
      return <p>You don't have events assigned</p>;
    }
  };

  render() {
    const adminWindow = this.state.adminWindow;
    const eventId = this.state.displayEventId;
    return (
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Events</h3>
              </CardHeader>
              <CardBody>{this.renderDayDisplay()}</CardBody>
            </Card>
          </Col>
          {adminWindow ? <EventDetail eventId={eventId} /> : ''}
        </Row>
      </div>
    );
  }
}
