import React from 'react';
import { Route, Switch } from 'react-router-dom';
import welcomeRoutes from 'routes/welcome';
import logo from 'assets/img/vsw-logo.svg';

export default class Welcome extends React.Component {

  render() {
    return (
      <section id="welcome">
        <div className="text-center">
          <br/><br/>
          <img src={logo} alt="VSW Logo" className="text-center" width="150" />
        </div>
        <h1>VSW HQ</h1>

        <Switch>
          { welcomeRoutes.map(({ path, component }, key) => <Route path={path} component={component} key={key} />) }
        </Switch>
      </section>
    );
  }

}
