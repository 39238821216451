import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

export default class FullScreenLoader extends React.Component {

  render() {
    return (
      <div className="wrapper bg-dark">
        <ReactLoading
          type={this.props.animation || 'bubbles'}
          color="#FAFAFA" height="50%" width="50%" className="fullscreen-loader" />
      </div>
    );
  }

  static propTypes = { animation: PropTypes.string };

}
