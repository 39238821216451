import firebase from 'firebase';
import firebaseui from 'firebaseui';

const FirebaseUIConfig = {
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  tosUrl: 'https://google.com',
  privacyPolicyUrl: 'https://google.com'
};

export default FirebaseUIConfig;
