import React from 'react';
import PropTypes from 'prop-types';
import { Route, Router, Switch } from 'react-router-dom';
import firebase from 'firebase';
import Dashboard from 'layouts/Dashboard';
import Welcome from 'layouts/Welcome';

export default class App extends React.Component {

  state = { isLoggedIn: false };

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => this.setState({ isLoggedIn: !!user })
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { isLoggedIn } = this.state;

    return (
      <Router history={this.props.hist}>
        <Switch>
          <Route path="/" component={isLoggedIn ? Dashboard : Welcome} />
        </Switch>
      </Router>
    );
  }

  static propTypes = { hist: PropTypes.object };

}
