import React, { Component } from 'react';
import PropType from 'prop-types';
import EventSlot from './EventSlot';
import moment from 'moment';
export default class DayDisplay extends Component {
  render() {
    const eventDate = this.props.date.split('T')[0];
    return (
      <div>
        <h6>{moment(eventDate).format('LL')}</h6>
        {this.props.events.map((event, i) => (
          <EventSlot
            key={i}
            eventId={event.eventId}
            event={event.meta}
            user={this.props.user}
            handleAdminClick={this.props.handleAdminClick}
          />
        ))}
      </div>
    );
  }
}

DayDisplay.propTypes = {
  date: PropType.string,
  events: PropType.arrayOf(PropType.object),
  user: PropType.object,
  handleAdminClick: PropType.func,
};
