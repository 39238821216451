import firebase from 'firebase';

export default class BackendAPI {
  /**
   * @param {string} path
   * @param {string} method
   * @param {object} body
   * @returns {Promise}
   */
  static async request(path, method = 'GET', body = null) {
    const url = `${process.env.REACT_APP_BACKEND_API}${path}`;
    const headers = { Accept: 'application/json' };

    if (body) {
      headers['Content-Type'] = 'application/json; charset=utf-8';
    }

    const call = () =>
      fetch(url, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined
      }).then(response => {
        if (response.ok) {
          return response.json();
        }

        return response.json().then(err => {
          throw err;
        });
      });

    if (firebase.auth().currentUser) {
      const token = await firebase
        .auth()
        .currentUser.getIdToken(true);
      headers['Authorization'] = `Bearer ${token}`;
      return call();
    }

    return call();
  }

  /**
   * @param {string} path
   * @returns {Promise}
   */
  static get(path) {
    return this.request(path);
  }

  /**
   * @param {string} path
   * @param {object} body
   * @returns {Promise}
   */
  static post(path, body = {}) {
    return this.request(path, 'POST', body);
  }
  /**
   * @param {string} path
   * @param {object} body
   * @returns {Promise}
   */
  static put(path, body = {}) {
    return this.request(path, 'PUT', body);
  }

  static delete(path, body = {}) {
    return this.request(path, 'DELETE');
  }

  /**
   * @param {string} path
   * @param {object} body
   * @returns {Promise}
   */
  static patch(path, body = {}) {
    return this.request(path, 'PATCH', body);
  }

  /** USERS **/

  /**
   * Create a user
   *
   * @typedef {object} createUser
   * @typedef {string} createUser.firstName
   * @typedef {string} createUser.lastName
   * @typedef {string} createUser.phoneNumber
   * @typedef {string} createUser.email
   * @typedef {string} createUser.password
   *
   * @param {createUser}
   * @returns {Promise}
   */
  static createUser({ firstName, lastName, phoneNumber, email, password }) {
    return this.post('/users', {
      firstName,
      lastName,
      phoneNumber,
      email,
      password
    });
  }

  /**
   * Get a user (own by default)
   *
   * @param {string} userId
   * @returns {Promise}
   */
  static getUser(userId = firebase.auth().currentUser.uid) {
    return this.get(`/users/${userId}`);
  }
  /**
   * update a user information
   *
   * @param {string} userId
   * @returns {Promise}
   */
  static updateUser(userId, { firstName, lastName, phoneNumber, email, address }) {
    return this.put(`/users/${userId}`, { firstName, lastName, phoneNumber, email, address })
  }

  /**
   * delete a user information
   *
   * @param {string} userId
   * @returns {Promise}
   */
  static deleteUser(userId) {
    return this.delete(`/users/${userId}`)
  }

  /**
   * Get a list of all users
   *
   * @returns {Promise}
   */
  static getAllUsers() {
    return this.get('/users');
  }

  /**
   * Toggle a user's admin role
   *
   * @param {string} userId
   * @returns {Promise}
   */
  static toggleAdmin(userId) {
    return this.post(`/users/${userId}/toggle-admin`);
  }

  /** BANNERS **/

  /**
   * Create a Banner
   *
   * @typedef {object} createBanner
   * @typedef {string} createBanner.name
   * @typedef {string} createBanner.imageUrl
   * @typedef {string} createBanner.type
   * @typedef {string} createBanner.description
   *
   * @param {createBanner}
   * @returns {Promise}
   */
  static createBanner({ name, imageUrl, type, description }) {
    return this.post('/banners', {
      name,
      imageUrl,
      type,
      description
    });
  }

  /**
   * Get a list of all banners
   *
   * @returns {Promise}
   */
  static getAllBanners() {
    return this.get('/banners');
  }

  /** Get the banner with the given id
   *
   * @param {string} bannerId
   * @returns {Promise}
   */
  static getBanner(bannerId) {
    return this.get(`/banners/${bannerId}`);
  }

  /** Get the qrcode for the banner with the given id
   *
   * @param {string} bannerId
   * @returns {Promise}
   */
  static getQrCode(bannerId) {
    return this.get(`/banners/${bannerId}/qrcode`);
  }

  /** Update the banner for the banner with the given id
   *
   * @param {string} bannerId
   */
  static updateBanner(bannerId, newBanner) {
    return this.patch(`/banners/${bannerId}`, newBanner);
  }

  /** Delete the banner for the banner with the gikven id
   *
   * @param {string} bannerId
   */
  static deleteBanner(bannerId) {
    return this.delete(`/banners/${bannerId}`);
  }

  /** Update the banner for the banner with the given id
   *
   * @param {string} eventId
   * @param {string} userId
   * @returns {Promise}
   */
  static assignUserToEvent(eventId, userId) {
    return this.patch(`/events/${eventId}/assign/user/${userId}`)
  }

  static removeUserFromEvent(eventId, userId) {
    return this.patch(`/events/${eventId}/remove/user/${userId}`)
  }

  static assignBannerToEvent(eventId, bannerId) {
    return this.patch(`/events/${eventId}/assign/banner/${bannerId}`)
  }

  static removeBannerFromEvent(eventId, bannerId) {
    return this.patch(`/events/${eventId}/remove/banner/${bannerId}`)
  }

  static checkInUserToEvent(eventId, userId) {
    return this.patch(`/events/${eventId}/check-in/user/${userId}`)
  }

  static checkOutUserFromEvent(eventId, userId) {
    return this.patch(`/events/${eventId}/check-out/user/${userId}`)
  }

  static getEvent(eventId) {
    return this.get(`/events/${eventId}`)
  }

  /** Check-out the banner from HQ with the given id
   *
   * @param {string} bannerId
   */
  static checkoutHQ(bannerId) {
    return this.patch(`/banners/${bannerId}/check-out/hq`);
  }


  /** Check-in the banner to HQ with the given id
   *
   * @param {string} bannerId
   */
  static checkinHQ(bannerId) {
    return this.patch(`/banners/${bannerId}/check-in/hq`);
  }

  /** Check-in the banner to the venue with the given id
   *
   * @param {string} bannerId
   */
  static checkinVenue(bannerId) {
    return this.patch(`/banners/${bannerId}/check-in/venue`);
  }

  /** Check-out the banner from the venue with the given id
   *
   * @param {string} bannerId
   */
  static checkoutVenue(bannerId) {
    return this.patch(`/banners/${bannerId}/check-out/venue`);
  }

}
