import Register from 'views/Register';
import Login from 'views/Login';

const welcomeRoutes = [
  {
    path: '/register',
    component: Register
  },
  {
    path: '/',
    component: Login
  }
];

export default welcomeRoutes;
