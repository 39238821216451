import React from 'react';
import ReactLoading from 'react-loading';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, FormGroup, Input } from 'reactstrap';
import * as QRCode from 'qrcode';
import Button from 'components/CustomButton/CustomButton.jsx';
import ImageHandler from 'services/ImageHandler';
import NotificationAlert from 'react-notification-alert';
import BackendAPI from 'services/BackendAPI';

class BannerProfile extends React.Component {
    constructor() {
        super();
        this.state = {
            editing: false,
            loadingScreen: true,
            name: '',
            type: '',
            description: '',
            imageUrl:'',
            bannerImage: null,
            id: '',
            events: [],
            state: '',
            history: [],
            qrCode: '',
        };

        this.fetchBanner = this.fetchBanner.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.prettifyBannerState = this.prettifyBannerState.bind(this);
        this.deleteBanner = this.deleteBanner.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderQRCode = this.renderQRCode.bind(this);
        this.handleNewImage = this.handleNewImage.bind(this);
        this.updateBanner = this.updateBanner.bind(this);
        this.statusErrorNotification = this.statusErrorNotification.bind(this);
        this.checkoutHQ = this.checkoutHQ.bind(this);
        this.checkinHQ = this.checkinHQ.bind(this);
        this.checkoutVenue = this.checkoutVenue.bind(this);
        this.checkinVenue = this.checkinVenue.bind(this);
    }

    componentDidMount(){
        this.fetchBanner();
    }

    /* Fetch Banner information and persist it to state */
    fetchBanner() {
        const bannerId = this.props.match.params.id;
        //TODO: handle case if cannot get bannerId from URL
        BackendAPI.getBanner(bannerId).then(fetchedBanner => {
            let banner = fetchedBanner;
            BackendAPI.getQrCode(bannerId).then(qrCode => {
                this.setState({
                    id: bannerId,
                    name: banner.name,
                    imageUrl: banner.imageUrl,
                    type: banner.type,
                    state: banner.state,
                    description: banner.description,
                    events: banner.events,
                    history: banner.history,
                    qrCode: qrCode,
                    loadingScreen: false
                });
                this.renderQRCode(qrCode);
            });
        })
        .catch(err => {
            //TODO: handle case when cannot fetch banner data
        });
    }


    /* Event handler for Editing the banner profile */
    toggleEdit = (event) => {
        this.setState(prevState => ({
            editing: !prevState.editing
        }));
    }

    /* Prettifys the state of the banner */
    prettifyBannerState(bannerState) {
        const prettyState = (bannerState === 'IN_HQ') ? 'HQ' :
            ((bannerState === 'IN_TRANSITION') ? 'In Transit' : ((bannerState === 'IN_VENUE') ?
                'Venue' : 'Lost'
            ));
        return prettyState;
    }
    
    async deleteBanner(){
        await BackendAPI.deleteBanner(this.state.id);
        let path = '/banners'
        this.props.history.push(path);
    }

    /* Event handler for changes in Form inputs */
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'file' ? target.files[0] : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    renderQRCode(qrCode) {
        const canvas = document.getElementById('qrCode');
        QRCode.toCanvas(canvas, qrCode, {width: 300}, function (err) {
            if (err) throw err
        })
    }

    /*Sequentially verifies file, uploads it, then creates a banner using the returned url and form data */
    handleNewImage(event) {
        const uploadedFile = this.state.bannerImage;
        const bannerName = this.state.name;
        const imageUrl = this.state.imageUrl;
        //if no file was uploaded then no need to update the banner image url
        if(uploadedFile === null){
            this.updateBanner(bannerName, imageUrl);
        }
        //if a file was uploaded then we need to verify, upload, and update the image url
        ImageHandler.verifyFile(uploadedFile)
             .then(validImage => ImageHandler.uploadImage(validImage))
             .then((imageUrl) => {
                this.updateBanner(bannerName, imageUrl);
              })
             .catch(error => {
                let options = {
                  place: 'tc',
                  message: error,
                  type: 'danger',
                  icon: 'fas fa-exclamation',
                  autoDismiss: 3,
                  closeButton: false
                };
                this.refs.notificationAlert.notificationAlert(options);
              })
    }

    updateBanner(bannerName, bannerUrl){
        let collection = {};
        collection.name = bannerName;
        collection.imageUrl = bannerUrl;

        this.toggleEdit();
        console.log('About to go to BackendAPI');
        //use BackendAPI to create a banner
        return BackendAPI.updateBanner(this.state.id, collection).then(banner => {
            this.fetchBanner();
        });
    }

    statusErrorNotification = (error) => {
        let options = {
        place: 'tc',
        message: error.errors[0].message,
        type: 'danger',
        icon: 'fas fa-exclamation',
        autoDismiss: 3,
        closeButton: false
        }
        this.refs.notificationAlert.notificationAlert(options);
      };

    checkoutHQ = (bannerId) => {
        BackendAPI.checkoutHQ(bannerId)
        .then(fetchedBanner => {
            this.setState({
                state: fetchedBanner.state,
                history: fetchedBanner.history
            })
        })
        .catch((err) => this.statusErrorNotification(err))
    }

    checkinHQ = (bannerId) => {
        BackendAPI.checkinHQ(bannerId)
        .then(fetchedBanner => {
            this.setState({
                state: fetchedBanner.state,
                history: fetchedBanner.history
            })
        })
        .catch((err) => this.statusErrorNotification(err))       
    }

    checkoutVenue = (bannerId) => {
        BackendAPI.checkoutVenue(bannerId)
        .then(fetchedBanner => {
            this.setState({
                state: fetchedBanner.state,
                history: fetchedBanner.history
            })
        })
        .catch((err) => this.statusErrorNotification(err))      
    }

    checkinVenue = (bannerId) => {
        BackendAPI.checkinVenue(bannerId)
        .then(fetchedBanner => {
            this.setState({
                state: fetchedBanner.state,
                history: fetchedBanner.history
            })
        })
        .catch((err) => this.statusErrorNotification(err))
    }

    editingBannerDetails = () => (
        <div>
        <FormGroup ncols={['col-md-12']}>
            <label>
                Banner Name
                </label>
            <Input
                type='text'
                name='name'
                value={this.state.name}
                placeholder='Banner Name'
                onChange={this.handleChange}
            />
        </FormGroup>
        <FormGroup ncols={['col-md-12']}>
            <label>Banner Type</label>
            <Input
                id='typeInput'
                type='text'
                name='type'
                value={(this.state.type === 'vsw') ? 'VSW' : 'Sponsor'}
                disabled={true}
                placeholder='Banner Type'
            />
        </FormGroup>
        <label>Banner Image</label>
        <div style={{ marginBottom: 20 + 'px' }}>
            <Input
                type='file'
                name='bannerImage'
                onChange={this.handleChange}
                accept='image/*'
            />
        </div>

        <FormGroup ncols={['col-md-12']}>
            <label>Banner Description</label>
            <Input
                type='text'
                name='type'
                value={this.state.description}
                disabled={true}
                placeholder='none'
            />
        </FormGroup>
        </div>
    );

    displayBannerDetails(){
        return (
            <div className='bannerDetailsDiv'>
                <div className='row'>
                    <div className='col-8 bannerHeader' ><h4 className='bannerHeaderText'>Banner Details</h4></div>
                    <div className='col-4' align='right'><Button className='text-right' color='danger' round onClick={() => this.deleteBanner()}>Delete Banner</Button></div>
                </div>
                <hr className='bannerHeaderDivider'></hr>
                <span><strong>ID:</strong></span><p className='text-muted'>{this.state.id}</p>
                <span><strong>Name:</strong></span><p className='text-muted'>{this.state.name}</p>
                <span><strong>Category:</strong></span><p className='text-muted'>{this.state.type}</p>
                <span><strong>Description:</strong></span><p className='text-muted'>{this.state.description}</p>
            </div>
        );
    }

    renderLoadingScreen = () => (
        <div>
            <ReactLoading
                className='margin-zero-auto'
                type='bubbles' color='#222222' />
        </div>
    );

    renderHistoryRow = ( record, key ) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        var date = new Date(record.date);
        return(
        <tr key={key}>
        <td>{ this.prettifyBannerState(record.state) }</td>
        <td>{ record.eventId }</td>
        <td>{ date.toLocaleDateString('en-US', options) }</td>
      </tr>
    );
    }

    renderEventRow = (event, key) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        var date = new Date(event.eventDate);
        return (
            <tr key={key}>
                <td>{event.eventId}</td>
                <td>{date.toLocaleDateString('en-US', options)}</td>
            </tr>
        );
    }

    bannerStatusHandler = (bannerState) => {
        let HQ = <p><span className="fas fa-circle fa-2x"></span></p>
        let inTransit = <p><span className="fas fa-circle fa-2x"></span></p>
        let venue = <p><span className="fas fa-circle fa-2x"></span></p>
        let HQbutton
        let inTransitButton
        let venueButton

        switch (bannerState) {
            case 'IN_HQ':
                HQ = <p><span className="fas fa-check-circle fa-2x"></span></p>
                HQbutton = <button className="btn btn-primary btn-sm" onClick={() => this.checkoutHQ(this.state.id)}>Checkout <span className="fas fa-chevron-right"></span></button>
                break;
            case 'IN_TRANSITION':
                inTransit = <p><span className="fas fa-check-circle fa-2x"></span></p>
                inTransitButton = 
                    <div>
                        <button className="btn btn-primary btn-sm" onClick={() => this.checkinHQ(this.state.id)}><span className="fas fa-chevron-left"></span></button>
                        <button className="btn btn-primary btn-sm" onClick={() => this.checkinVenue(this.state.id)}><span className="fas fa-chevron-right"></span></button>
                    </div>
                break;
            case 'IN_VENUE':
                venue = <p><span className="fas fa-check-circle fa-2x"></span></p>
                venueButton = <button className="btn btn-primary btn-sm" onClick={() => this.checkoutVenue(this.state.id)}><span className="fas fa-chevron-left"></span>   Checkout</button>
                break;
            default:
        }
        
        return(
            <div>
                <div className='row'>
                    <div className='col text-center'>
                        {HQ}
                        <h6>HQ</h6>
                    </div>
                    <div className='col text-center'>
                        {inTransit}
                        <h6>In Transit</h6>
                    </div>
                    <div className='col text-center'>
                        {venue}
                        <h6>Venue</h6>
                    </div>
                </div>
                <div className='row'>
                    <div className='col text-center'>
                        {HQbutton}
                    </div>
                    <div className='col text-center'>
                        {inTransitButton}
                    </div>
                    <div className='col text-center'>
                        {venueButton}
                    </div>
                </div>
            </div>
        );
    }

    renderBannerProfile = () => {
        let bannerDetails = (this.state.editing) ? this.editingBannerDetails() : this.displayBannerDetails()
        let buttonState = (this.state.editing) ? 'Cancel Edit' : 'Edit Profile'
        let bannerStatus = this.bannerStatusHandler(this.state.state)
        return (
            <div>
                <div className="container" className="banner-details">
                    <div className="row">
                        <div className="col-6">
                            <Card>
                                <CardBody>
                                    <form>
                                        {bannerDetails}
                                        <Row>
                                            <Col>
                                                <div className='text-left'>
                                                    <Button color='primary' round onClick={this.toggleEdit}>{buttonState}</Button>
                                                    <Button disabled={!this.state.editing} color='primary' round onClick={() => this.handleNewImage()}> Save Changes</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div>
                                        <h6 className='title text-center'>STATUS</h6>
                                        <br></br>
                                        {bannerStatus} 
                                        <br></br>                                      
                                        <Table responsive>
                                            <thead className='text-primary'>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.events.map(
                                                        (event, key) => this.renderEventRow(event, key)
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-6">
                            <Card md={9} xs={6}>
                                <CardBody>
                                    <img src={this.state.imageUrl}></img>
                                </CardBody>
                            </Card>
                            <Card md={9} xs={6}>
                                <CardBody>
                                    <div align='center'>
                                        <canvas id='qrCode'></canvas>
                                    </div>
                                </CardBody>
                            </Card>                            
                        </div>
                    </div>
                </div>


                <Card className='banner-history'>
                    <CardHeader>
                        <CardTitle tag='h4'>History</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Table responsive>
                            <thead className='text-primary'>
                                <tr>
                                    <th>Status</th>
                                    <th>Event</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.history.map(
                                        (record, key) => this.renderHistoryRow(record, key)
                                    )
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        )
    }

    render() {
        return (
            <div className='content'>
            <NotificationAlert ref='notificationAlert' />
            {
                (this.state.loadingScreen) ?
                    this.renderLoadingScreen() :
                    this.renderBannerProfile()
            }
            </div>
        );
    }
}

export default BannerProfile;
