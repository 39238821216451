import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Card, CardHeader, CardBody } from "reactstrap";
import "./UserProfile.css";

export default class EventStatus extends Component {
  componentDidMount() {
    //Backend Requirement: Need to provide event Id for assigned event in the user model?
    // maybe discussion need for how do we want to handle this.
  }

  render() {
    return (
      <Card>
        <CardHeader className="Title">
          <Row>
            <Col className="StatusHeaderText">
              <strong>Status:</strong>
            </Col>
            <Col className="StatusHeaderText">On Duty</Col>
            <Col>
              <Button>Start/End Duty</Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col>
                  <p>
                    <strong>Shift Start Time: </strong>
                    {this.props.startTime}
                  </p>
                </Col>
                <Col>
                  <p>
                    <strong>Event Name: </strong>
                    {this.props.name}
                  </p>
                </Col>
                <Col>
                  <p>
                    <strong>Location: </strong>
                    {this.props.location}
                  </p>
                </Col>
              </Row>
              <Row className="StatusBannerActionRow">
                <Col className="StatusHeaderText">
                  <strong>Scanned / Collected Banners?</strong>
                </Col>
                <Col>
                  <Button>Return Banner</Button>
                </Col>
              </Row>
              <Row className="StatusBannerActionRow">
                <Col>Banner 1</Col>
                <Col>Banner 2</Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

EventStatus.propTypes = {
  startTime: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string
};
