import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Card, CardHeader, CardBody } from "reactstrap";
import "./UserProfile.css";

export default class EventTeamContacts extends Component {
  RenderRows = ({ id, name, phone }) => (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{phone}</td>
    </tr>
  );

  render() {
    let renderRow;
    if (this.props.user.userEvents) {
      renderRow = this.props.user.userEvents.map(user => {
        this.RenderRows(user);
      });
    }
    return (
      <Card>
        <CardHeader className="Title">Event Team</CardHeader>
        <CardBody>
          <Table>
            <thead className="text-primary">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>{renderRow}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

EventTeamContacts.propTypes = {
  user: PropTypes.object
};
