import React from 'react';
import { Container, Col } from 'reactstrap';
import PropType from 'prop-types';

export const BasicInfoTable = props => (
  <Col>
    <Container fluid={true}>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>ID:</strong>
              </p>
            </td>
            <td>
              <p>{props.user.uid}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>First Name:</strong>
              </p>
            </td>
            <td>
              <p>{props.firstName}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Last Name:</strong>
              </p>
            </td>
            <td>
              <p>{props.lastName}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Email:</strong>
              </p>
            </td>
            <td>
              <p>{props.user.email}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Address:</strong>
              </p>
            </td>
            <td>
              <p>{props.user.address}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Phone:</strong>
              </p>
            </td>
            <td>
              <p>{props.user.phoneNumber}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Notes:</strong>
              </p>
            </td>
            <td>
              <p>{props.user.notes}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  </Col>
);

BasicInfoTable.propTypes = {
  user: PropType.object,
  firstName: PropType.string,
  lastName: PropType.string
}