import React, { Component } from 'react';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import './UserProfile.css';

export default class HistoryTable extends Component {
  RenderHistroryRows = ({ id, start, end, location, team, banner, notes }) => (
    <tr>
      <td>{id}</td>
      <td>{start}</td>
      <td>{end}</td>
      <td>{location}</td>
      <td>{team}</td>
      <td>{banner}</td>
      <td>{notes}</td>
    </tr>
  );

  render() {
    let renderRow;
    if (this.props.userEvents) {
      renderRow = this.props.userEvents.map(event => {
        this.RenderHistoryRows(event);
      });
    }
    return (
      <Card>
        <CardHeader className="Title">History</CardHeader>
        <CardBody>
          <Table>
            <thead className="text-primary">
              <tr>
                <th>#</th>
                <th>Shift Start</th>
                <th>Shift End</th>
                <th>Event NAme</th>
                <th>Location</th>
                <th>Event Team</th>
                <th>Banners</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>{renderRow}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

HistoryTable.propTypes = {
  userEvents: PropTypes.arrayOf(PropTypes.object)
};
